import { stripRichTextWrapperTag } from '@/utils/html';
import { getEkomiData } from '~/utils/ekomi';
import { highlightV2WrapperTagAdapter } from '~/components/highlight-v2/adapter';

export const inputCardAdapter = (input) => {
  if (!input) return;

  const adaptPicture = (picture) => ({
    alt: picture.name,
    filename: picture.cdn_link,
  });

  const options = (input.items || []).map((item) => ({
    label: item.label,
    value: item.value,
    picture: item.picture && adaptPicture(item.picture),
  }));

  return {
    label: stripRichTextWrapperTag(input.primary.label),
    inputRadio: {
      name: input.primary.name,
      variant: 'withPicture',
      inputs: options,
    },
  };
};

export const inputRadioAdapter = (input) => {
  if (!input) return;

  const options = (input.items || []).map((item) => ({
    label: item.label,
    value: item.value,
  }));

  return {
    label: stripRichTextWrapperTag(input.primary.label),
    inputRadio: {
      name: input.primary.name,
      variant: 'withRadio',
      withoutCount: false,
      inputs: options,
    },
  };
};

export default async ({ primary }, pageData, context) => {
  const { $prismic, $enhancedLinkSerializer } = context;
  const moduleId = primary?.hook_module_v2?.id;

  if (!moduleId) {
    return;
  }

  const hookModuleV2 = await $prismic.client.getByID(moduleId);
  const hookModuleV2Data = hookModuleV2?.data;

  let ratings;
  if (hookModuleV2Data.with_ekomi_rating) {
    const ekomiAccount =
      pageData.ekomi_account ?? pageData.market?.data?.ekomi_account;
    const ekomiData = await getEkomiData(ekomiAccount, 'reviews', context);

    ratings = {
      rating: ekomiData?.average_rating,
      variant: 'form',
      showText: false,
    };
  }

  return {
    hasFixedButton: primary.fixed_button,
    type: hookModuleV2Data.type,
    variant: hookModuleV2Data.variant,
    title: stripRichTextWrapperTag(hookModuleV2Data.title),
    surtitle: stripRichTextWrapperTag(hookModuleV2Data.surtitle),
    highlight: highlightV2WrapperTagAdapter(
      hookModuleV2Data.body.find(
        ({ slice_type }) => slice_type === 'highlight_v2'
      )
    ),
    ratings,
    form: {
      title: stripRichTextWrapperTag(hookModuleV2Data.form_title),
      ctaLabel: hookModuleV2Data.cta_label,
      cards: inputCardAdapter(
        hookModuleV2Data.body.find(
          ({ slice_type }) => slice_type === 'input_card'
        )
      ),
      radios: inputRadioAdapter(
        hookModuleV2Data.body.find(
          ({ slice_type }) => slice_type === 'input_radio'
        )
      ),
    },
    cta: $enhancedLinkSerializer(hookModuleV2Data?.cta_link),
    submitUrl: hookModuleV2Data?.cta_link?.url,
  };
};
